import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("dblclick", this.toggle)
  }

  toggle(e) {
    const node = e.target.parentNode.parentNode.querySelector("portal-example.debug") || e.target.closest("portal-example.debug")
    node?.toggleAttribute("hidden")
  }
}
