import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.initLoaderUpdates()
  }

  disconnected() {
    this.stopLoaderUpdates()
  }

  rejected() {
    this.stopLoaderUpdates()
  }

  updateLoaderMessage(message) {
    this.containerTarget.innerText = message
  }

  initLoaderUpdates() {
    this.messages = this.loadMessages()
    this.nextMessageIn10Seconds()
  }

  stopLoaderUpdates() {
    clearTimeout(this.loaderTimer)
  }

  nextMessageIn10Seconds() {
    const self = this
    self.loaderTimer = setTimeout(function() {
      const nextMessage = self.messages.shift()

      if (nextMessage) {
        self.updateLoaderMessage(nextMessage)
        self.nextMessageIn10Seconds()
      }
    }, 10000)
  }

  loadMessages() {
    return [
      this.element.getAttribute("data-loader-10s"),
      this.element.getAttribute("data-loader-20s"),
      this.element.getAttribute("data-loader-30s"),
      this.element.getAttribute("data-loader-40s"),
      this.element.getAttribute("data-loader-50s"),
      this.element.getAttribute("data-loader-60s")
    ].filter(x => !!x)
  }
}
