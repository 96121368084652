import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["anchor"]

  change(event) {
    event.preventDefault()
    let tab

    if (typeof event.detail === "object") {
      tab = event.detail.target
    } else {
      if (event.target.classList.contains("tab")) {
        tab = event.target
      } else {
        tab = event.target.closest("a.tab")
      }
    }
    const link = tab.href.split("#")[1]

    this.anchorTarget.href = `#${link}`
  }
}
