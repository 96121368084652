import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    baseUrl: String
  }
  static targets = ["childSelect"]

  change(event) {
    const self = this
    let branch_slug = event.target.selectedOptions[0].value
    if (branch_slug === "") {
      // Blank option has been selected by user, escape
      return
    }
    let url = self.#listVersionsUrl(branch_slug)

    fetch(url)
      .then(response => response.json())
      .then((data) => {
        self.#emptySelect()
        data.versions.forEach(version => {
          self.#fillSelect(version)
        })
      })
  }

  immutableUrl(event) {
    if (!event || typeof event.detail !== "object" || !event.detail.previous) {
      this.dispatch("immutableUrl", {detail: {previous: this.childSelectTarget.value}})
    } else {
      const comparisonString = new URL(window.location.href).pathname.split("/").pop()
      const newComparisonString = `${this.childSelectTarget.value}..${event.detail.previous}`
      const newUrl = window.location.href.replace(comparisonString, newComparisonString)

      history.replaceState({}, document.title, newUrl)
    }
  }

  #listVersionsUrl(branch) {
    return `${this.baseUrlValue}?branch=${branch}`
  }

  #emptySelect() {
    Array.from(this.childSelectTarget).forEach(option => {
      option.remove()
    })
  }

  #fillSelect(version) {
    const option = document.createElement("option")
    option.value = version.id
    option.innerHTML = version.label
    this.childSelectTarget.append(option)
  }
}
