import { Controller } from "@hotwired/stimulus"
import { requestIdleCallback } from "global/javascript/request_idle_callback"

export default class extends Controller {
  static targets = ["mainMenu", "header", "entry"]

  connect() {
    this.visibleEntries = []

    const headerHeight = this.headerTarget.getBoundingClientRect().height

    const observer = new IntersectionObserver(
      this.handleIntersectingEntries.bind(this),
      {
        rootMargin: `-${headerHeight}px 0px 0px 0px`
      }
    )

    this.entryTargets.forEach(entry => { observer.observe(entry) })
  }

  handleIntersectingEntries(entries) {
    const self = this
    requestIdleCallback(() => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          self.insertEntry(entry)
        } else {
          self.removeEntry(entry)
        }
      })

      if (this.visibleEntries.length) {
        this.setActive(this.visibleEntries[0])
        self.element.setAttribute("data-active-container", this.visibleEntries[0].getAttribute("id"))
      }
    })
  }

  setActive(target) {
    const ignoreScroll = this.element.getAttribute("data-ignore-scroll")
    if (!ignoreScroll) {
      this.activeItem?.classList.remove("active")
      this.activeParent?.classList.remove("active")

      const id = target.getAttribute("id")

      // the $= selector is to get an element ending with <id>
      // the || operator is to ensure that we get the element container the matching id and not one
      // that contains the id
      this.activeItem = this.mainMenuTarget.querySelector(`[data-section-id="${id}"]`)

      if (this.activeItem) {
        const current = this.element.querySelector("[aria-current='true']")
        current?.setAttribute("aria-current", false)
        target.setAttribute("aria-current", true)
        const parentId = this.activeItem.getAttribute("data-menu-parent")

        if (parentId) {
          this.activeParent = this.mainMenuTarget.querySelector(`[href$="/${parentId}"]`) || this.mainMenuTarget.querySelector(`[href$="#${parentId}"]`)

          if (this.activeParent) {
            this.activeParent.classList.add("active")
          }
        }

        const href = new URL(window.location.search, this.activeItem.href)
        history.replaceState({}, "", href.toString())
        this.activeItem.classList.add("active")
        this.activeItem.scrollIntoView({ block: "center", behavior: "instant" })
      }
    }
  }

  insertEntry(entry) {
    const top = entry.target.getBoundingClientRect().top

    if (this.visibleEntries[0]?.getBoundingClientRect().top > top) {
      // We are scrolling up: we insert the target at the beginning of the Array
      this.visibleEntries.unshift(entry.target)
    } else {
      // Scroll down: push at the end of the Array
      this.visibleEntries.push(entry.target)
    }
  }

  removeEntry(entry) {
    const index = this.visibleEntries.indexOf(entry.target)
    if (index > -1) {
      this.visibleEntries.splice(index, 1)
    }
  }

  click(event) {
    event.preventDefault()
    const id = event.currentTarget.getAttribute("data-section-id")
    const activeItem = document.getElementById(id)

    if (activeItem) {
      if (activeItem && activeItem.tagName === "TURBO-FRAME" && activeItem.getAttribute("complete") === null) {
        this.dispatch("navigateToFrame", { target: document, prefix: false, detail: { section: activeItem } })
        activeItem.removeAttribute("loading")
        activeItem.reload()
      }
      activeItem.scrollIntoView()
    }
  }
}
