import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.currentFilter = this.element.querySelector(".active")
  }

  toggle(event) {
    const self = this
    this._setFilter(event.target)
    this.contentTargets.forEach((content) => {
      if (content.getAttribute("data-value") !== self.currentFilter.innerText) {
        content.style.display = "none"
      } else {
        content.style.display = "block"
      }
    })
  }

  reset(event) {
    this._setFilter(event.target)
    this.contentTargets.forEach(content => content.style.display = "block")
  }

  _setFilter(button) {
    if (!button.classList.contains("active")) {
      this.currentFilter?.classList.remove("active")
      button.classList.add("active")
      this.currentFilter = this.element.querySelector(".active")
    }}
}
