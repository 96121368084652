import { Controller } from "@hotwired/stimulus"



export default class extends Controller {
  static targets = ["loader", "icon", "responseContent", "responseStatus", "authMessage", "authMessageTemplate"]

  handleResponse(event) {
    const responsePromise = event.detail.responsePromise
    responsePromise.text().then((data) => {

      let content

      // Attempt to parse JSON
      try {
        content = JSON.parse(data)
      } catch {
        content = data
      }

      this.#setResponseStatusAndContent(responsePromise.status, JSON.stringify(content, null, 2))
    })
  }

  handleFailure(event) {
    const error = event.detail.responsePromise

    this.#unexpectedErrorHandler(error)
  }

  #unexpectedErrorHandler(error) {
    this.#setResponseStatusAndContent(error.name, error.message)
  }

  #setResponseStatusAndContent(status, content) {
    this.#setLoadingState(false)
    this.responseStatusTarget.innerText = status
    this.responseStatusTarget.dataset.statusCode = status
    this.responseStatusTarget.removeAttribute("hidden")
    this.responseContentTarget.textContent = content
    if (status === 401) {
      if (!this.hasAuthMessageTarget) {
        const template = this.authMessageTemplateTarget.content.cloneNode(true)
        const responseContainer = this.responseContentTarget.closest(".example-content")
        responseContainer.prepend(template)
      }
      this.dispatch("check-auth", { target: document })
    } else if (this.hasAuthMessageTarget) {
      this.authMessageTarget.remove()
    }
    this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.responseContentTarget)).highlight()
  }

  #setLoadingState(isLoading) {
    this.loaderTarget.setAttribute("aria-hidden", !isLoading)
    this.loaderTarget.setAttribute("aria-busy", isLoading)
    this.iconTarget.setAttribute("aria-hidden", isLoading)
  }
}
