import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "button", "item"]

  static outlets = ["explorer-send-request"]

  static values = { inputName: String }

  addOne(event) {
    event?.preventDefault() // can be called without an event, from explorer_share_controller
    const template = this.templateTarget.content.cloneNode(true)
    const index = this.itemTargets.length
    const currentItem = template.querySelector("[data-explorer-collection-target=item]")

    if (this.#isArray()) {
      this.#setUiIncrement(template, index)
    }

    if (this.hasButtonTarget) {
      this.element.insertBefore(template, this.buttonTarget)
    } else {
      this.element.append(template)
    }

    this.#updateInputsName(currentItem, index)
  }

  removeAll() {
    this.itemTargets.forEach((item) => {
      this.removeOne(
        {
          currentTarget: item,
          updateRequest: false
        }
      )
    })
  }

  removeOne(event) {
    const toDelete = event.currentTarget.closest(".property")
    const itemTarget = toDelete.querySelector("[data-explorer-collection-target=item]")
    const index = itemTarget.dataset.explorerCollectionIndex

    // Remove whole element from DOM
    toDelete.remove()

    this.#resetIndexes(index)
    this.#updateIncrement(index)

    if (event.updateRequest === undefined || event.updateRequest) {
      this.explorerSendRequestOutlet.updateRequest()
    }
  }

  #setUiIncrement(element, index) {
    element.querySelector(".increment").textContent = `[${index}]`
  }

  #updateIncrement(fromIndex) {
    if (!this.#isArray()) return

    this.itemTargets.forEach((item, index) => {
      if (fromIndex && index < fromIndex) {
        return
      }

      const property = item.closest(".property")
      this.#setUiIncrement(property, index)

      // Update input's name within the target item
      this.#updateInputsName(item, index)
    })
  }

  #updateInputsName(item, index) {
    // Apply to all descendant inputs (can be childs but also
    // gran-childs, etc...)
    item.querySelectorAll("input").forEach((inputElement) => {
      let defaultInputNameValue = inputElement.name
      let fullName = defaultInputNameValue.replaceAll(/\[[\d ]+?\]/g, "[]")
      let currentItem = inputElement.closest("[data-explorer-collection-target=item]")

      this.#buildAndStoreParentIndexes(currentItem, index).forEach(parentIndex => {
        // for each step in the loop, replace the first [] with correct increment value.
        fullName = fullName.replace("[]", `[${parentIndex}]`)
      })
      inputElement.name = fullName
    })
  }

  #buildAndStoreParentIndexes(item, index) {
    let parentIndexes = []
    const parentItem = item.parentNode.closest("[data-explorer-collection-target=item]")

    if (parentItem) {
      parentIndexes =
        parentIndexes.concat(parentItem.dataset.explorerCollectionIndexesValue.split(","))
    }
    if (this.#isArray()) {
      parentIndexes.push(index)
    }
    // Remember for later
    item.dataset.explorerCollectionIndexesValue = parentIndexes.join(",")

    // return list of parent indexes
    return parentIndexes
  }

  #isArray() {
    return this.inputNameValue.endsWith("[]")
  }

  #resetIndexes(fromIndex) {
    this.itemTargets.forEach((item, index) => {
      if (fromIndex && index < fromIndex) {
        return
      }

      delete item.dataset.explorerCollectionIndexesValue
      item.querySelectorAll("[data-explorer-collection-target=item]").forEach(childItem => {
        delete childItem.dataset.explorerCollectionIndexesValue
      })
    })
  }
}
