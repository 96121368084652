/* eslint no-console:0 */

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

require("portal/controllers")
require("controllers")
require("components/analytics")

// global JS
import "../global/javascript"

// CSS
import "../portal/css/portal.scss"

Rails.start()
