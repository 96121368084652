import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

const channel = createConsumer()

export default class extends Controller {
  static targets = ["content"]

  static values = {
    id: String,
    loaded: Boolean
  }

  connect() {
    this.initChannel()
  }

  initChannel() {
    if (!this.#existingSubscription()) {
      const self = this

      channel.subscriptions.create(
        {
          channel: "Docs::PreviewsChannel",
          id: self.idValue,
          loaded: self.loadedValue
        }, {
          received: function(data) {
            if (data.html) {
              self.contentTarget.replaceWith(document.createRange().createContextualFragment(data.html))
              // Manually fire a DOMContentLoaded event as if the page
              // loaded for the first time.
              document.dispatchEvent(new Event("DOMContentLoaded"))
            } else if (data.reload) {
              document.location.reload()
            }
          }
        }
      )
    }
  }

  #existingSubscription() {
    return channel.subscriptions.subscriptions.find((el) => {
      return JSON.parse(el.identifier).channel === "Docs::PreviewsChannel"
    })
  }
}
