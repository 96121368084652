import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "copyButton"]

  copy() {
    const originalAriaLabel = this.copyButtonTarget.getAttribute("aria-label")
    let cleanText = this.activeCodeContent.innerText

    if (this.activeCodeContent.classList.contains("language-curl-example")) {
      cleanText = cleanText.replace(/^\s*\$ /gm, "")
    }

    navigator.clipboard.writeText(cleanText)
    this.copyButtonTarget.setAttribute("aria-label", "Copied")

    setTimeout(() => {
      this.copyButtonTarget.setAttribute("aria-label", originalAriaLabel)
    }, 2000)
  }

  get activeCodeContent() {
    return this.contentTargets.find(target => !target.classList.contains("hidden")).querySelector("code, a")
  }
}
