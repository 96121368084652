import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "example", "dropdownTitle", "dropdownExample"]

  connect() {
    this.toggle()
    this.dropdownExampleTargets.forEach(example => {
      const nodeId = example.getAttribute("data-node-id")
      document.addEventListener(`tabs:change:${nodeId}`, this._openAssociatedExample.bind(this))
    })
  }

  _openAssociatedExample(event) {
    const {target} = event.detail
    const name = target.firstChild.textContent.trim()
    const exampleTitle = `${name.toLowerCase()} (generated)`

    const foundExample = this.dropdownExampleTargets.find(example => {
      return example.textContent.trim().toLowerCase().includes(exampleTitle)
    })

    if (foundExample) {
      this.toggle({currentTarget: foundExample})
    }
  }

  toggle(event) {
    if (event) {
      this.exampleTargets.forEach(example => {
        if (event.currentTarget.textContent.trim() == example.getAttribute("data-title")) {
          example.classList.add("active")
        } else {
          example.classList.remove("active")
        }
      })
      this.dropdownTitleTarget.innerText = event.currentTarget.textContent.trim()
      this.dropdownExampleTargets.forEach(example => {
        example.classList.remove("active")
      })

      event.currentTarget.classList.add("active")
    } else {
      this.exampleTargets.forEach((example, index) => {
        if (index !== 0) {
          example.classList.remove("active")
        }
      })
      this.dropdownExampleTargets[0].classList.add("active")
    }
  }
}
