import { Controller } from "@hotwired/stimulus"
import { trackAnalytics } from "../../global/javascript/track_analytics"

export default class extends Controller {
  static targets = ["requestForm", "urlForm", "shareField", "server", "currentServer"]

  static values = { versionId: String, trackUrl: String }

  static outlets = ["explorer-collection"]

  // following https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript/53221307#53221307,
  // this base64 object will be us ton encode and decode object to string with base64 format,
  // supporting characters outside of Ascii range (user may fill Explorer form with emoji, 💩 happens).
  base64 = {
    decode: s => Uint8Array.from(atob(s), c => c.charCodeAt(0)),
    encode: b => btoa(String.fromCharCode(...new Uint8Array(b))),
    decodeToString: s => new TextDecoder().decode(this.base64.decode(s)),
    encodeString: s => this.base64.encode(new TextEncoder().encode(s)),
  }

  connect() {
    const digestParam = new URL(document.location).searchParams.get("digest")

    if (digestParam) {
      this.#setSharedValues(digestParam)
      // fill query, path and headers parameters in urlForm, with easy value (not object or collection)
      this.#populateInputs(this.sharedValues.urlForm, this.urlFormTarget)

      // fill body parameters in requestForm, with easy values (not object or collection)
      this.#populateInputs(this.sharedValues.requestForm, this.requestFormTarget)
    }
  }

  // When user access to explorer page, if a search parameter 'digest' is detected,
  // this function will decode the digest parameter to a JS Object,
  // and stored these values in instance variable 'this.sharedValues'.
  #setSharedValues(digest) {
    const decodedString = this.#decodeString(digest)
    const decodedObject = JSON.parse(decodedString)

    this.sharedValues = decodedObject
  }

  // When modal "share request" is open by current user, URL to share is generated
  // here with a parameter 'digest'. This URL is included in copiable field.
  fillShareUrl() {
    this.shareFieldTarget.innerText = this.#generateShareUrl()
    trackAnalytics(this.trackUrlValue)
  }

  #generateShareUrl() {
    const shareUrl = new URL(window.location.href)
    shareUrl.search = ""
    shareUrl.searchParams.set("digest", this.#generateDigest())
    shareUrl.searchParams.set("server_id", this.#getCurrentServerId())
    shareUrl.searchParams.set("shared_at", Date.now())
    shareUrl.searchParams.set("shared_version_id", this.versionIdValue)

    return shareUrl.href
  }

  #getCurrentServerId() {
    const currentServer = this.serverTargets.find((server) => {
      return server.dataset.url === this.currentServerTarget.innerText.trim()
    })

    return currentServer.dataset.id
  }

  #generateDigest() {
    // create JS object, with top-level key values,
    // where all fields values will be stored.
    // this digest can also share additional informations (version_id, server, timestamp...)
    const urlFormData = new FormData(this.urlFormTarget)
    const requestFormData = new FormData(this.requestFormTarget)
    const digestObject = {
      urlForm: this.#formDataToObject(urlFormData),
      requestForm: this.#formDataToObject(requestFormData)
    }

    const stringToDigest = JSON.stringify(digestObject)
    const digest = this.#encodeString(stringToDigest) // safe Ascii support

    return digest
  }

  #formDataToObject(formData) {
    const formDataObject = {}

    for (let [key, value] of formData.entries()) {
      if (value === null || value === undefined) continue

      const trimmedValue = String(value).trim()

      if (trimmedValue !== "") {
        formDataObject[key] = trimmedValue
      }
    }

    return formDataObject
  }

  // When controller collection-controller is connected, verify if
  // there are some values in this.sharedValues for this element.  If
  // yes, call the addOne functionto open collection inputs.  Then,
  // call populateInputs to populate these inputs.
  //
  // Please note that the “populateInputs” could be done later (once
  // all form is loaded) instead of each time there's an open
  // collection.
  explorerCollectionOutletConnected(outletController, element) {
    const keyValues = this.sharedValues?.requestForm
    if (!keyValues) return

    const openedIndexes = []
    const {inputNameValue:inputName} = outletController
    // The requestForm values are key:value pairs where the key is the
    // input name (with indexes for collections) and value is the
    // target input value.
    // E.g. {"user[0][name]": "John", "user[1][name]": "Marie"}

    Object.keys(keyValues).forEach(key => {
      // Escape regexp chars from the inputname
      // $& means the whole matched string
      const escapedInputName = inputName.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      // Build a regexp from the input name to match the array indexes '[n]'
      const regexInputName = escapedInputName.replaceAll("\\[\\]", "\\[(\\d+)\\]")
      const matchData = key.match(new RegExp(`^${regexInputName}`))

      if (matchData) {
        // Remove first element (it's the matched text). All remaining
        // items the capturing groups of the matched text (all the
        // indexes).
        matchData.shift()
        // Remove last index which is related to the current
        // controller to remember if we already opened it or not. Only
        // if we are at the level of an array
        let index = null
        if (inputName.endsWith("[]")) {
          index = matchData.pop()
        }

        // if (key === "references[1][single_error][apis][0][id]") debugger

        // Fetch parent collection indexes (it's a string of indexes
        // separated by commas defined by the explorer-collection when
        // we add items)
        const parent = element.closest("[data-explorer-collection-target=item]")
        let parentIndexes = []
        if (parent?.dataset?.explorerCollectionIndexesValue) {
          parentIndexes = parent.dataset.explorerCollectionIndexesValue.split(",")
        }

        // Finally: decide whether we should open the
        // collection/object or not
        const shouldOpen = !openedIndexes.includes(index) && (
          JSON.stringify(matchData) === JSON.stringify(parentIndexes)
        )

        if (shouldOpen) {
          // Remember we've opened the current index
          openedIndexes.push(index)
          // Actually open the collection/object
          outletController.addOne()
        }
      }
    })

    // TODO: This could be done “later” (once the complete form has
    // finished loading) instead of each time there's a collection
    // controller that connects.
    this.#populateInputs(this.sharedValues.requestForm, element)
  }

  #populateInputs(values, element) {
    if (Object.keys(values).length === 0) return

    element.querySelectorAll("input").forEach((inputElement) => {
      const name = inputElement.name
      const value = values[name]

      if (value !== undefined) {
        this.#fillFormInputWithValue(inputElement, value)
        // remove value from values when it has been used (une boule sur le sapin de Noel)
        delete values[name]
      }
    })
  }

  #fillFormInputWithValue(input, value) {
    if (input !== undefined) {
      if (["radio", "checkbox"].includes(input.type)) {
        input.checked = !!value
      } else {
        input.value = value
      }
    }
  }

  #encodeString(serializedJSON) {
    return this.base64.encodeString(serializedJSON)
  }

  #decodeString(digestParam) {
    return this.base64.decodeToString(digestParam)
  }
}
