import { fetchCsrf } from "./fetch_csrf"

export async function  trackAnalytics(trackUrl) {
  const csrfToken = await fetchCsrf()

  fetch(trackUrl, {
    method: "post",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  })
}
