import { Application } from "@hotwired/stimulus"
import Hotkeys from "stimulus-hotkeys"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
// Manually register Hotkeys as a Stimulus controller
application.register("hotkeys", Hotkeys)

document.querySelector("body").classList.remove("no-js")
