import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  setActive(event) {
    if (event.currentTarget.contains(event.target)) {
      const activeItem = event.target.closest("details")
      if (!activeItem.open) {
        this.#deactivateParents(activeItem)
        activeItem.classList.add("active")
      } else {
        activeItem.classList.remove("active")
        const parent = activeItem.parentNode.closest("details")
        activeItem.querySelectorAll("details").forEach(details => {
          details.removeAttribute("open")
          details.classList.remove("active")
        })
        if (parent?.querySelectorAll("details.expandable-properties-list.active").length < 1) {
          parent.classList.add("active")
        }
      }
    }
  }

  #deactivateParents(container) {
    const parent = container.parentNode.closest("details")
    if (!parent) {
      return false
    }

    parent.classList.remove("active")
    return this.#deactivateParents(parent)
  }

  openParents(event) {
    this.#openParents(event.detail)
  }

  #openParents(element, isLeaf = true) {
    const parent = element.parentNode.closest("details")
    if (!parent) {
      return false
    }
    if (!parent.hasAttribute("open")) {
      parent.setAttribute("open", true)
    }
    isLeaf ? parent.classList.add("active") : parent.classList.remove("active")
  
    return this.#openParents(parent, false)
  }
}
