import { Controller } from "@hotwired/stimulus"
import { requestIdleCallback } from "global/javascript/request_idle_callback"

import hljs from "highlight.js/lib/core"
import bash from "highlight.js/lib/languages/bash"
import json from "highlight.js/lib/languages/json"
import shell from "highlight.js/lib/languages/shell"
import yaml from "highlight.js/lib/languages/yaml"
import curl from "highlightjs-curl/src/languages/curl"
import ruby from "highlight.js/lib/languages/ruby"
import python from "highlight.js/lib/languages/python"
import xml from "highlight.js/lib/languages/xml"

import "highlight.js/styles/color-brewer.css"

export default class extends Controller {
  static targets = ["entry"]

  connect() {
    hljs.registerLanguage("bash", bash)
    hljs.registerLanguage("shell", shell)
    hljs.registerLanguage("json", json)
    hljs.registerLanguage("yaml", yaml)
    hljs.registerLanguage("curl", curl)
    hljs.registerLanguage("ruby", ruby)
    hljs.registerLanguage("python", python)
    hljs.registerLanguage("xml", xml)

    this.highlight()
  }

  highlight() {
    const observer = new IntersectionObserver(this.highlightExamples)
    this.entryTargets.forEach(entry => { observer.observe(entry) })
  }

  highlightExamples(entries, observer) {
    requestIdleCallback(() => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          hljs.highlightElement(entry.target)
          observer.unobserve(entry.target)
        }
      })
    })
  }
}
