import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "description"]

  onPostSuccess(event) {
    let [data,,] = event.detail
    this.descriptionTarget.innerHTML = data.message
    this.descriptionTarget.classList.remove("hidden")
  }

  onPostError(event) {
    let [data,,] = event.detail
    this.descriptionTarget.innerHTML = data.message
    this.descriptionTarget.classList.remove("hidden")
  }
}
