import { Controller } from "@hotwired/stimulus"
import { nodeIdFromUrl } from "global/javascript/utils"

export default class extends Controller {
  static targets = ["container", "content"]

  connect() {
    const self = this
    this.containerTargets.forEach(container => {
      self.maxHeight = container.getAttribute("data-maxheight")
      if (self.maxHeight) {
        const content = container.querySelector("[data-expander-target=\"content\"]")
        if (parseInt(self.maxHeight, 10) > content.offsetHeight) {
          container.classList.remove("expandable")
        } else {
          content.style.maxHeight = self.maxHeight
        }
      }
    })
  }

  expandFromNav(event) {
    const id = nodeIdFromUrl(new URL(event.currentTarget.getAttribute("href")))
    this._expandOperations(document.getElementById(id.replace(/#/, "")), true)
  }

  expandFromButton(event) {
    this._expandOperations(event.currentTarget)
  }

  toggleFromButton(event) {
    const button = event.currentTarget
    const container = button.closest("[data-expander-target=\"container\"]")
    if (container?.classList.contains("expandable")) {
      this._expandOperations(button)
    } else {
      this._collapseOperations(button)
    }
  }

  collapseFromButton(event) {
    this._collapseOperations(event.currentTarget)
  }

  expandFromHighlight(event) {
    this._expandOperations(event.detail, false)
  }

  _expandOperations(section, scroll) {
    const container = section.closest("[data-expander-target=\"container\"]")
    if (container) {
      const expandable = container.querySelector("[data-expander-target=\"content\"]")
      const button = container.querySelector(".expand-button")
      container.classList.remove("expandable")
      expandable.style.maxHeight = ""
      if (button.hasAttribute("data-collapse-label")) {
        button.querySelector("span").textContent = button.getAttribute("data-collapse-label")
      }
    }
    if (scroll) {
      section.scrollIntoView()
    }
  }

  _collapseOperations(section) {
    const container = section.closest("[data-expander-target=\"container\"]")
    const expandable = container.querySelector("[data-expander-target=\"content\"]")
    const button = container.querySelector(".expand-button")
    container.classList.add("expandable")
    expandable.style.maxHeight = self.maxHeight
    if (button.hasAttribute("data-expand-label")) {
      button.querySelector("span").textContent = button.getAttribute("data-expand-label")
    }
  }
}
