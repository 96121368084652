import { CurlGenerator } from "curl-generator"
export default class RequestToCurl {
  constructor(requestOptions) {
    this.requestOptions = requestOptions
  }

  // Convert Request object to cURL command
  convert() {
    const params = this.#parseRequestOptions(this.requestOptions)
    return CurlGenerator(params)
  }

  #capitalizeHeaderKey(headerKey) {
    return headerKey
      .toLowerCase()
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  #parseRequestOptions(requestOptions) {
    // Copy headers from the Request object
    const headers = {}
    requestOptions.headers.forEach((value, key) => {
      headers[this.#capitalizeHeaderKey(key)] = value
    })

    let parsing = {
      url: requestOptions.url,
      method: requestOptions.method,
      headers: headers
    }

    if (requestOptions.body) {
      parsing.body = requestOptions.body
    }

    return parsing
  }
}
