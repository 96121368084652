import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button", "select"]
  
  connect() {
    this.contentTargets.forEach((content, index) => {
      if (index > 0) {
        content.setAttribute("aria-hidden", true)
        document.querySelector(`[data-body-id="${content.getAttribute("id")}"]`)?.setAttribute("hidden", true)
      }
    })
  }

  show(event) {
    const bodyId = event.params.id
    const bodyScope = event.params.scope

    this.#switchBody(event.params.id)
    this.#switchSelection(event.target)
    if (bodyScope) {
      this.#switchExample(bodyId, bodyScope)
    }
  }

  #switchBody(id) {
    this.contentTargets.forEach(element => {
      if (!element.classList.contains("content-type")) {
        element.setAttribute("hidden", true)
      }
    })
    document.getElementById(id).removeAttribute("hidden")
  }

  #switchSelection(target) {
    this.selectTarget.textContent = target.textContent.trim()
    this.buttonTargets.forEach(button => button.setAttribute("aria-selected", false))
    target.setAttribute("aria-selected", true)
  }

  #switchExample(id, scope) {
    const exampleTarget = document.querySelector(`[data-body-id=${id}]`)
    const scopeTargets = document.querySelectorAll(`[data-body-scope="${scope}"]`)

    Array.from(scopeTargets).forEach(target => target.setAttribute("hidden", true))
    exampleTarget?.removeAttribute("hidden")
  }
}
