import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["authSchemeSelect", "authSchemeLabel"]
  connect() {
    this.updateSelectedAuthScheme()
  }

  updateSelectedAuthScheme() {
    this.authSchemeLabelTarget.innerText = this.#computedAuthSchemeLabel()
  }

  #selectedAuthenticationScheme() {
    return this.authSchemeSelectTarget.selectedOptions[0]
  }

  #computedAuthSchemeLabel() {
    return this.#selectedAuthenticationScheme().value
  }
}
