import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["operationSkeleton", "textSkeleton"]

  initialize() {
    this.element.querySelectorAll("turbo-frame[loading='lazy']:not([complete]):not([busy])").forEach((frame) => {
      frame.removeAttribute("disabled")
    })
  }

  saveNavigationParams(event) {
    this.activeSection = event.detail.section
    this.attributeId = event.detail.attributeId
    this.highlight = event.detail.highlight
  }

  beforeRequest(event) {
    const frame = event.target
    const skeletonName = frame.getAttribute("data-skeleton-target-name") || "textSkeletonTarget"
    const skeleton = this[skeletonName].content.cloneNode(true)

    // Ideally we could target only :-moz-only-whitespace divs but the
    // existing :empty CSS selector doesn't select nodes with a child
    // with whitespace only text...
    if (!frame.hasChildNodes() || (frame.childNodes.length === 1 && frame.childNodes[0].nodeType === Node.TEXT_NODE)) {
      frame.appendChild(skeleton)
    }
  }

  frameMissing(event) {
    event.preventDefault()
    event.target.innerHTML = "An error has occured while retrieving this content."
    event.target.classList.add("missing-frame")
  }

  beforeRender(event) {
    if (!this.highlight && this.#isAboveViewport(event.target)) {
      this.#saveScrollPosition()
      event.detail.render = (currentElement, newElement) => {
        window.requestAnimationFrame(() => {
          currentElement.innerHTML = newElement.innerHTML
          this.#maintainScrollTop()
        })
      }
    }
  }

  afterRender(event) {
    if (event.target === this.activeSection) {
      const attributeId = this.attributeId
      const attribute = attributeId && document.getElementById(attributeId)
      const activeItem = attribute || this.activeSection
      activeItem.scrollIntoView()
      if (this.highlight) {
        this.dispatch("highlight", { target: document, detail: { element: activeItem, scroll: true }, prefix: false })
      }
    }
  }

  afterLoad() {
    this.#resetNavigationParams()
  }

  #saveScrollPosition() {
    this.scrollPosition = this.element.scrollHeight - this.element.scrollTop
  }

  #maintainScrollTop() {
    this.element.scrollTop = this.element.scrollHeight - this.scrollPosition
  }

  #resetNavigationParams() {
    this.activeSection = null
    this.attributeId = null
    this.highlight = null
  }

  #isAboveViewport(element) {
    return element.getBoundingClientRect().top < 0
  }
}
