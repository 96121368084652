import { Controller } from "@hotwired/stimulus"
import consumer from "../action-cable-consumer"

export default class extends Controller {
  static targets = ["content"]

  static values = {
    versionId: String
  }

  connect() {
    this.initChannel()
  }

  initChannel() {
    consumer.subscriptions.create(
      {
        channel: "VersionCable::LoadingChannel",
        versionId: this.versionIdValue
      }, {
        received: function(data) {
          if (data.reload) {
            document.location.reload()
          }
        }
      }
    )
  }
}
