import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

const channel = createConsumer()

export default class extends Controller {
  static values = {
    id: String
  }
  static targets = ["body"]

  connect() {
    if (this.idValue) {
      this.initChannel()
    }
  }

  initChannel() {
    const self = this
    channel.subscriptions.create(
      {
        channel: "Docs::DocumentationChangesChannel",
        id: self.idValue
      }, {
        received: function(data) {
          self.bodyTarget.innerHTML = data.html
        }
      }
    )
  }
}
