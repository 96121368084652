import { Controller } from "@hotwired/stimulus"
import { nodeIdFromUrl } from "global/javascript/utils"

export default class extends Controller {
  static targets = ["dismiss"]

  connect() {
    const activeId = nodeIdFromUrl(new URL(window.location.href))
    const activeItem = document.getElementById(activeId)
    if (activeItem) {
      const container = activeItem.closest(".highlight-container") || activeItem.querySelector(".highlight-container")
      container && this.#displayHighlight(activeItem, container, true)
    }
  }

  setHighlight(event) {
    event.preventDefault()
    const target = event.detail.element || event.target
    const scroll = event.detail.scroll
    const container = target && target.closest(".highlight-container")
    container && this.#displayHighlight(target, container, scroll)
  }

  highlightFromAnchor(event) {
    event.preventDefault()
    const target = event.currentTarget
    const container = target && target.closest(".highlight-container")
    container && this.#displayHighlight(target, container)
    this.#copyToClipboard(target)
  }

  dismiss(event) {
    if (!event.target.closest(".anchor-link") && !event.target.closest("dialog")) {
      const container = document.querySelector(".shared")
      this.#removeHighlight(container)
    }
  }

  #displayHighlight(target, container, scroll = false) {
    this.#removeHighlight(this.element.querySelector(".shared"))

    const tabParent = container.closest("[data-tabs-target='panel']")
    if (tabParent && scroll) {
      const tab = document.querySelector(`[name="${tabParent.getAttribute("id")}"]`)
      tab && tab.click()
    }
    this.dispatch("open-details", { target: document, detail: target, prefix: false })
    container.closest("[data-expander-target=\"container\"]") && this.dispatch("expand", { detail : container })

    container.classList.add("shared")
    this.#appendDismissButton(container)
    scroll && target.scrollIntoView()
  }

  #appendDismissButton(container) {
    if (!container.querySelector(":scope > .dismiss-highlight")) {
      container.appendChild(document.importNode(this.dismissTarget.content, true))
    }
  }

  #removeHighlight(container) {
    container && container.classList.remove("shared")
  }

  sendHighlight() {
    if (this.sendHighlightEvent) {
      this.dispatch("tabs", { detail: this.container })
    }
  }

  #copyToClipboard(link) {
    const href = link.getAttribute("href")
    history.pushState({}, "", href)
    navigator.clipboard.writeText(window.location)
    link.classList.add("active")

    setTimeout(() => {
      link.classList.remove("active")
    }, 2000)
  }
}
